import React from 'react';
import Image from 'next/image';
import { StyledBottomBanner } from './CsBanner.styled';
import { TextCaption, TextTitle } from '@hanwhalife/shared-libs/design-system';
import { ICsBannerItem } from './CsBanenr.types';
import { useTheme } from '@hanwhalife/shared-libs/emotion/react';
import { HLI_CDN_STATIC } from '@hanwhalife/constants';

export const CsBanner = ({
  id,
  type = 'contract',
  tagName,
  bannerTitle,
  bannerOverlineText,
  bannerCaption,
  bannerURL,
  bannerImageURL,
  ...rest
}: ICsBannerItem) => {
  const theme = useTheme();

  /**
   * 공통 고객센터 안내 배너
   * 다이렉트 상품가입 문의 : 1544-6385
   * 계약관련 업무 : 1588-6363
   * 보험금 청구 관련 : 1588-6363
   * 기타
   */

  return (
    <StyledBottomBanner
      id={id}
      as={tagName ? tagName : 'a'}
      href={bannerURL ? bannerURL : `tel: ${type === 'product' ? '1544-6385' : '1588-6363'}`}
      {...rest}
    >
      <div className="text-box">
        <TextTitle
          level="title3"
          overlineText={bannerOverlineText ? bannerOverlineText : '고객센터로 문의하기'}
          overlineColorText={theme.colorText_body_1}
        >
          {bannerTitle ? bannerTitle : type === 'product' ? '1544-6385' : '1588-6363'}
        </TextTitle>
        <TextCaption>{bannerCaption ? bannerCaption : '평일 오전 9시 ~ 오후 6시'}</TextCaption>
      </div>
      <div className="image-box">
        <Image
          src={bannerImageURL ? bannerImageURL : `${HLI_CDN_STATIC}/hlp/micro/images/illust/cs.svg`}
          alt=""
          width={100}
          height={117}
          className="banner-img"
          unoptimized={true}
        />
      </div>
    </StyledBottomBanner>
  );
};
