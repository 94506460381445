/*****************************
 * Event 관련 유틸
 ******************************/

const createScrollStopListener = ({
  element,
  callback,
  timeout = 100
}: {
  element: HTMLElement | Window;
  callback: () => void;
  timeout?: number;
}) => {
  let removed = false;
  let handle: NodeJS.Timeout | null = null;
  const onScroll = () => {
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, timeout);
  };
  element.addEventListener('scroll', onScroll);

  return () => {
    if (removed) {
      return;
    }
    removed = true;
    if (handle) {
      clearTimeout(handle);
    }
    element.removeEventListener('scroll', onScroll);
  };
};

const toFit = (cb: () => void) => {
  let tick = false;

  return function trigger() {
    if (tick) {
      return;
    }

    tick = true;

    return requestAnimationFrame(() => {
      tick = false;

      return cb();
    });
  };
};

const getGenderCode = (genderKr: string) => {
  if (genderKr === '남성') {
    return 'M';
  }

  if (genderKr === '여성') {
    return 'F';
  }

  return '';
};
// GA Event Send
interface IGAProps {
  event: string;
  ep_screen_Name: string;
  ep_event_Label: string;
  ep_user_Login?: 'Y' | 'N';
  ep_platform_Channel?: 'MO_WEB' | 'PC_WEB' | 'WEB' | 'WEBinAPP';
  ep_tab_Status?: string;
  ep_product_Name?: string;
  ep_button_Text?: string;
  ep_product_Category?: string;
  ep_statusCard_Type?: string;
  ep_statusCard_Number?: string;
  ep_region?: string;
  ep_city?: string;
  ep_date?: string;
  ep_time?: string;
  ep_FP?: string;
}
type GAParams = {
  [key: string]: string;
};

/**
 * GA 전송을 위한 타입 처리
 * @param param0
 * @returns
 */
function convertGAProps({ event, ep_screen_Name, ep_event_Label, ...optionalProps }: IGAProps): GAParams {
  const result: GAParams = {
    event,
    ep_screen_Name,
    ep_event_Label
  };

  Object.entries(optionalProps).forEach(([key, value]) => {
    if (value !== undefined) {
      result[key] = value;
    }
  });

  return result;
}

/**
 * GA 전송
 * @param eventObj
 */
const sendEvent_GA = (eventObj: IGAProps) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(convertGAProps(eventObj));
};

/**
 * [모바일웹] 라온 키패드 Show, Hide 이벤트
 *
 * - show/hide시 Input 위치 제어
 */
const handleViewWithMobileKeypad = (type: string, ref?: any) => {
  const targetInputEl = ref ? ref.getRef() : null;
  const contentWrapper = document.querySelector('#content');
  // 하단 푸터 CTA 영역 높이
  const FOOTER_HEIGHT = 88;

  // 키패드 닫을때
  if (!targetInputEl || !contentWrapper || type === 'hide') {
    contentWrapper?.removeAttribute('style');
    document.querySelector('body')?.setAttribute('style', `overflow:unset`);

    return;
  }

  // 키패드 열때
  if (type === 'show') {
    const bodyHeight = window.innerHeight;
    const inputPositionTop = targetInputEl.getBoundingClientRect().top;
    const keypadEl = document.querySelector(`#mtk_${targetInputEl.id}`) as HTMLInputElement;
    const keypadHeight = parseInt(getComputedStyle(keypadEl).height);

    // 입력 input이 가상키패드에 가려질때만
    if (keypadHeight > bodyHeight - (inputPositionTop + targetInputEl.clientHeight)) {
      // 하단 키패드에 가려지지 않게 Input을 -TranslateY
      contentWrapper?.setAttribute(
        'style',
        `transform:translateY(-${Math.abs(window.innerHeight - inputPositionTop - keypadHeight - FOOTER_HEIGHT)}px)`
      );
      document.querySelector('body')?.setAttribute('style', `overflow:hidden`);
    }
  }
};

export { createScrollStopListener, toFit, getGenderCode, sendEvent_GA, handleViewWithMobileKeypad };
