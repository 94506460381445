import { useEffect } from 'react';
import * as Sentry from '@hanwhalife/shared-libs/sentry';
import { useLoginInfo } from '@hanwhalife/shared-store';

/**
 * 센트리 설정을 초기화 합니다.
 */
export const useInitializeSentryConfig = () => {
  const { loginInfo } = useLoginInfo();

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        username: loginInfo.integrationMemberName,
        phoneNumber: loginInfo.phoneNumber,
        customerId: loginInfo.customerId,
        deviceId: loginInfo.deviceID
      });

      scope.setTags({
        appVer: loginInfo.appVer,
        deviceId: loginInfo.deviceID,
        carrierName: loginInfo.carrierName,
        networkType: loginInfo.networkType
      });
    });

    return () => {
      Sentry.configureScope((scope) => scope.clear());
    };
  }, [loginInfo]);
};
