import { useEffect, useState } from 'react';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { useHlpRouter } from '@hanwhalife/reactjs/router';
import { Spinner } from '@hanwhalife/shared-libs/design-system';

/**
 * 로그인이 필요한 페이지가 있으면 해당 컴포넌트로 감싸주세요.
 * 살짝 깜빡일 수 있습니다.
 * @param param0
 * @returns
 */
export const LoginRequired = ({
  children,
  isLoginRequired = true
}: {
  children: React.ReactNode;
  isLoginRequired?: boolean;
}) => {
  const router = useHlpRouter();
  const [isCheckingPageStatus, setIsCheckingPageStatus] = useState(true);
  const {
    loginInfo: { isLogined, isInitialized }
  } = useLoginInfo();

  useEffect(() => {
    const doLoginCheck = async () => {
      if (isLoginRequired && isInitialized && isLogined === false) {
        // 로그인이 필요한데 로그인이 안되어 있으면 로그인 페이지로 이동
        return await router.goToLoginPage();
      }
      setIsCheckingPageStatus(false);
    };

    doLoginCheck();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginRequired, isInitialized, isLogined]);

  if (isCheckingPageStatus) {
    return <Spinner isSpinning position="fixed-center" />;
  }

  return <>{children}</>;
};
