import styled from '@hanwhalife/shared-libs/emotion/styled';

/**
 * 완료 페이지 등 하단에 들어가는 고객센터, 바로가기 배너
 */
export const StyledBottomBanner = styled.div<{ href?: string }>`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing.spacing_large};
  background-color: ${({ theme }) => theme.colorSurface_neutral};
  border-radius: ${({ theme }) => theme.radius.borderRadius_xLarge};
  ${({ theme }) => theme.shadow.boxShadow_large};
`;
