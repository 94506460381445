import { getCookie } from '@hanwhalife/shared-libs/cookies';
import {
  getUuid,
  getBrowserVersion,
  getOsName,
  getOsVersion,
  getUserAgent,
  isHlpWebview
} from '@hanwhalife/shared-utils';
import { useLoginInfo } from '@hanwhalife/shared-store';

interface Parameter {
  forceInit?: boolean;
}

/**
 *  기본 로그인 전역 정보 세팅
 * @returns setDefaultLoginInfo
 */
export const useDefaultLoginInfo = () => {
  const { setLoginInfo, initLoginInfo } = useLoginInfo();

  /**
   * forceInit true를 주면 전역 데이터를 초기화하고 기본 정보를 세팅합니다.
   * @param param
   */
  const setDefaultLoginInfo = (param?: Parameter) => {
    const { forceInit } = param ?? { forceInit: false };

    if (forceInit) {
      initLoginInfo();
    }

    // 로그인 유무(쿠키로 판단)
    const isLogined = getCookie('_snack') === '24';

    setLoginInfo({
      deviceID: getUuid(),
      loginChannel: 'DIRECT',
      isInitialized: true,
      platformName: getOsName(),
      platformVersion: getOsVersion(),
      appVer: getBrowserVersion(),
      platform: getUserAgent(),
      isNative: isHlpWebview(),
      isLogined,
      loginType: isLogined ? getCookie('_lgt') || '99' : 'U'
    });
  };

  return { setDefaultLoginInfo };
};
