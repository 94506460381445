import qs from 'qs';

/*****************************
 * URL 처리 관련 유틸
 ******************************/

const BASE_ORIGIN = process.env.NEXT_PUBLIC_DIRECT_HOST;

/**
 * query 객체를 string으로 변환해줍니다.
 * //! deprecated - objectToQueryString로 대체 예정
 */
export const getWebQueryStringUrl = (query?: Record<string, string | string[] | undefined> | null) => {
  const queryParam = qs.stringify(query);

  return queryParam ? `${queryParam}` : ``;
};

/**
 * 두 개의 쿼리스트링을 병합하고 중복을 제거하는 함수
 * @param queryString1 - 첫 번째 쿼리스트링 (예: location.search)
 * @param queryString2 - 두 번째 쿼리스트링
 * @returns 병합된 URLSearchParams 객체
 */
export const mergeQueryStringsURLSearchParamsObject = (
  queryString1: string,
  queryString2: string,
  options: { allowOverwrite?: boolean } = { allowOverwrite: true }
): URLSearchParams => {
  // URLSearchParams 객체 생성 (자동으로 '?' 처리)
  const params1 = new URLSearchParams(queryString1);
  const params2 = new URLSearchParams(queryString2);

  // 병합된 파라미터를 저장할 새 객체
  const mergedParams = new URLSearchParams();

  // 첫 번째 쿼리스트링의 모든 파라미터 추가
  params1.forEach((value, key) => {
    mergedParams.append(key, value);
  });

  // 두 번째 쿼리스트링의 모든 파라미터 추가 (중복 시 덮어쓰기)
  params2.forEach((value, key) => {
    if (options.allowOverwrite && mergedParams.has(key)) {
      mergedParams.delete(key);
    }
    mergedParams.append(key, value);
  });

  return mergedParams;
};

/**
 * URLSearchParams 객체를 대상 URL에 추가하는 함수
 * @param targetUrl - 쿼리스트링을 추가할 대상 URL
 * @param params - 추가할 파라미터
 * @returns 최종 URL 객체
 */
export const appendSearchParamsToTargetUrlObject = (targetUrl: string, params: URLSearchParams): URL => {
  const targetUrlObj = new URL(targetUrl, BASE_ORIGIN);

  // 병합된 모든 파라미터를 대상 URL에 추가
  params.forEach((value, key) => {
    targetUrlObj.searchParams.append(key, value);
  });

  return targetUrlObj;
};

/**
 * 두 개의 쿼리스트링을 병합하고 대상 URL에 추가하는 함수
 * @param targetUrl - 쿼리스트링을 추가할 대상 URL
 * @param queryString1 - 첫 번째 쿼리스트링
 * @param queryString2 - 두 번째 쿼리스트링
 * @returns 최종 URL 객체
 */
export const mergeAndAppendToTargetUrlObject = (
  targetUrl: string,
  queryString1: string,
  queryString2: string,
  options: { allowOverwrite?: boolean } = { allowOverwrite: true }
): URL => {
  const mergedParams = mergeQueryStringsURLSearchParamsObject(queryString1, queryString2, options);

  return appendSearchParamsToTargetUrlObject(targetUrl, mergedParams);
};

/**
 * Object 를 넣으면 key=value 형태의 문자열을 리턴합니다.
 * @param obj
 * @returns key=value 형태의 문자열('?' 물음표 제외)
 */
export const objectToQueryString = (obj: Record<string, string | undefined>) => {
  const paramsObj = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined) {
      paramsObj.append(key, obj[key] ?? '');
    }
  }

  return paramsObj.toString();
};
