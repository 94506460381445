import { useRouter as usePageRouter } from 'next/router';
import { isPc, isHlpWebview } from '@hanwhalife/shared-utils/bomUtil';
import { isNoAccessWhenLogin, mergeAndAppendToTargetUrlObject } from '@hanwhalife/shared-utils';
import { bridge } from '@hanwhalife/bridge';
import { YesNo } from '@hanwhalife/ts-types';
import { IMoveWebPageArgs, IWebPushOptions, Url, TransitionOptions, ILoginPageOptions } from './router.types';
import { BLOCK_LIST } from './routing-info';
import { getFrontendServiceFromUrl } from './router.utils';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { DIRECT_HOST } from '@hanwhalife/constants';
import { useLoadInitialQueryString } from '../hooks/util/useInitialQueryString';

/**
 * 이동 처리는 모두 여기서 처리
 * @returns
 */
export const useHlpRouter = () => {
  const pageRouter = usePageRouter();
  const {
    loginInfo: { isMember }
  } = useLoginInfo();
  const initQuery = useLoadInitialQueryString();

  class HlpRouter {}

  const customFunctionMap = {
    /**
     * 커스텀 push
     * @param url
     * @param as
     * @param options
     * @returns
     */
    push(url: Url, as?: Url, options?: TransitionOptions) {
      // custom logic 필요시 작성

      return pageRouter.push(url, as, options);
    },

    /**
     * 커스텀 replace
     * @param url
     * @param as
     * @param options
     * @returns
     */
    replace(url: Url, as?: Url, options?: TransitionOptions) {
      // custom logic 필요시 작성

      return pageRouter.replace(url, as, options);
    },

    /**
     * login page 이동처리 (redirectUrl를 입력하는 대로 redirectUrl로 랜딩됩니다.)
     * redirectUrl 미 입력시 기본 현재, 로그인 후 접속 안되는 페이지일 경우 무시
     * redirectUrl: Full URL을 입력해주세요.
     * @param param0
     * @returns
     */
    goToLoginPage(options?: ILoginPageOptions) {
      // 리다이렉트 무시 pathname
      const isSkipPathname =
        isNoAccessWhenLogin(location.pathname) ||
        location.pathname === '/' ||
        location.pathname === '/app/individual/withdrawal';

      // redirect할 url
      const redirectUrl = options?.redirectUrl ?? (isSkipPathname ? '' : location.href);
      const replace = options?.replace ?? false;

      const pathname = `/app/login${redirectUrl ? `?redirectUrl=${encodeURIComponent(redirectUrl)}` : ``}`;

      if (replace) {
        return pageRouter.replace(pathname);
      }

      return pageRouter.push(pathname);
    },

    /**
     * 로그인페이지 이동 처리
     * Web, Native 분기처리가 되어 있습니다. (Promise를 리턴합니다.)
     * 필요하면 사용하세요.
     * @param args
     */
    goToLoginByPlatform(options?: ILoginPageOptions) {
      if (isHlpWebview()) {
        // 앱 설치 안한 케이스이면 회원가입 페이지로이동
        if (isMember === false) {
          return bridge.joinPage();
        }

        // 앱 로그인 페이지로 이동
        return bridge.loginPage();
      }

      // 다이렉트웹 로그인 페이지로이동
      return this.goToLoginPage(options);
    },

    /**
     * full url or pathname을 입력 했을때 입력값을 보고 금융, 상품 페이지로 라우팅
     * 같은 도메인이면 push, 상품이면 href, 이외에는 새창
     * @param Full URL
     * @returns
     */
    async moveLink(url: string) {
      const appCode = getFrontendServiceFromUrl(url);

      // 이동할 URL
      const targetUrl = this.getLink(url) as string;

      // 우리 웹이면 라우터 이동(pathname + search)
      if (appCode === 'hlp-direct') {
        return pageRouter.push(targetUrl);
      }

      // 상품 프론트엔드면 href로 이동(full url: origin + pathname + search)
      if (appCode === 'hlp-product') {
        return (window.location.href = targetUrl);
      }

      // 나머지 새창
      return window.open(targetUrl, '_blank', 'noopener');
    },

    /**
     * https://가 들어가 있는 full url -> 홈페이지, 온슈어, 다이렉트웹 이동처리
     * @param url
     * @returns
     */
    getLink(url: string) {
      const appCode = getFrontendServiceFromUrl(url);

      const { origin, pathname, search } = new URL(url, DIRECT_HOST);

      // 우리 웹이면 pathname 이후
      if (appCode === 'hlp-direct') {
        return pathname + search;
      }

      // 상품 URL 구하기
      if (appCode === 'hlp-product') {
        return mergeAndAppendToTargetUrlObject(origin + pathname, search, initQuery).href;
      }

      // 나머지 (홈페이지)
      return isPc() ? url.replaceAll('https://m.', 'https://www.').replaceAll('https://qam.', 'https://qa.') : url;
    },

    /**
     * a tag target 리턴
     * @param url
     * @returns
     */
    getTarget(url: string) {
      if (url) {
        const appCode = getFrontendServiceFromUrl(url);
        if (appCode === 'hlp-direct') {
          return undefined;
        }

        if (appCode === 'hlp-product') {
          return '_self';
        }

        return '_blank';
      } else {
        return '_self';
      }
    },

    /**
     * a tag rel 리턴
     * @param url
     * @returns
     */
    getRel(url: string) {
      return this.getTarget(url) === '_blank' ? 'noopener' : undefined;
    },

    /**
     * a tag title 리턴
     * @param url
     * @returns
     */
    getTitle(url: string) {
      return this.getTarget(url) === '_blank' ? '새창열림' : undefined;
    },

    /**
     * 뒤로가기(for Web, Native)
     * @param args
     */
    backScreen(args: { reload?: YesNo; byHardware?: boolean } = { reload: 'N', byHardware: false }) {
      const { asPath } = pageRouter;

      if (isHlpWebview()) {
        // 뒤로가기 막아야 하는 페이지에서는 return
        if (args.byHardware && BLOCK_LIST.some((item) => asPath.includes(item))) {
          console.log(`BLOCK---LIST: ${asPath}`);

          return;
        }

        return bridge.webBack({ reload: args.reload as YesNo });
      }

      return pageRouter.back();
    },

    /**
     * 화면 닫기(for Native)
     * @param args
     */
    //+ 사유: Web, App 같이 쓸 필요 없음
    closeScreen(args: { reload: YesNo } = { reload: 'N' }) {
      if (isHlpWebview()) {
        return bridge.webClose({ reload: args.reload });
      }

      return window.close();
    },

    /**
     * 화면 이동(for Web, Native)
     * @param args
     * @param pushOptions
     */
    //! TODO: 삭제예정 (Deprecated)
    //+ 사유: Web, App 같이 쓸 필요도 없고 신규 plugin 사용 예정
    moveWebPage(args: IMoveWebPageArgs, pushOptions?: Omit<IWebPushOptions, 'url'>) {
      // 웹이거나 라우터 사용여부가 Y일경우 라우터로 이동
      if (args.path && (!isHlpWebview() || args.routerUseYn === 'Y')) {
        return pageRouter.push(args.path, pushOptions?.as, pushOptions?.options);
      }

      return bridge.moveWebPage({ ...args });
    },

    /**
     * 메인탭 화면 이동(only Native)
     * @param args
     * @param pushOptions
     */
    //! TODO: 삭제예정 (Deprecated)
    //+ 사유: Web, App 같이 쓸 필요도 없고 신규 plugin 사용 예정
    moveMainTabPage(tab: '0' | '1' | '2') {
      this.moveWebPage({ channelType: 'main', path: tab });
    },

    /**
     * 로그인페이지 이동(for Web, Native)
     * @param args
     */
    //! TODO: 삭제예정 (Deprecated)
    //+ 사유: Web, App 같이 쓸 필요 없음
    loginPage(options?: ILoginPageOptions) {
      if (isHlpWebview()) {
        return bridge.loginPage();
      }

      return this.goToLoginPage(options);
    },
    /**
     * 회원가입페이지 이동(for Web, Native)
     * @param args
     */
    //! TODO: 삭제예정
    //+ 사유: Web, App 같이 쓸 필요 없음
    joinPage() {
      if (isHlpWebview()) {
        return bridge.joinPage();
      }

      // TODO: 회원가입 페이지 이동
      //return pageRouter.push('/login');
      return;
    }
  };

  Object.assign(HlpRouter.prototype, pageRouter);
  Object.assign(HlpRouter.prototype, customFunctionMap);

  return new HlpRouter() as typeof pageRouter & typeof customFunctionMap;
};
