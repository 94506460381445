import { create } from '@hanwhalife/shared-libs/zustand';
import { IClientSession } from '@hanwhalife/ts-types';

/** 로그인 정보 초기 값 */
const initialState: IClientSession = {
  // SharedLoginInfo
  integrationMemberName: '',
  integrationMemberMobilePhoneNumber: '',
  maskedIntegrationMemberMobilePhoneNumber: '',
  customerMobilePhoneNumber: '',
  maskedCustomerMobilePhoneNumber: '',
  isMobilePhoneNumberMatch: undefined,
  maskedIntegrationMemberEmail: '',
  integrationMemberBirthWithResidentRegistrationNumberLastDigit: '',
  residentRegistrationNumber: '',
  integrationMemberJoinDateTime: '',
  isCustomer: undefined,
  isTruthOrFalsehoodConfirmation: undefined,
  isIdentityConfirmationTarget: undefined,

  // IOptionalLoginInfo
  accessToken: '',
  refreshToken: '',
  integrationMemberStatus: undefined,
  tokenType: '',
  expiresIn: -1,
  customerId: '',
  age: -1,
  gender: undefined,

  // deprecated 필드(one-pass 강업 이후 삭제)
  hp_1: '',
  hp_2: '',
  hp_3: '',
  MIN: '',
  phoneNumber: '',

  // Device
  deviceID: '',
  appVer: '',
  modelName: '',
  platformName: '',
  platformVersion: '',
  carrierName: '',
  networkType: '',

  // 앱 전용
  isMember: undefined,

  // 나머지
  loginType: '',
  certType: undefined,
  loginChannel: '',
  platform: undefined,
  isNative: undefined,
  isLogined: false,
  isInitialized: false
};

interface IClientSessionState {
  /** login 정보, device 정보 */
  loginInfo: IClientSession;
  setLoginInfo: (loginInfo: Partial<IClientSession>) => void;
  initLoginInfo: () => void;
}

/**
 * Login Info
 */
export const useLoginInfo = create<IClientSessionState>((set) => ({
  loginInfo: initialState,
  setLoginInfo: (loginInfo) => {
    console.log('[Zustand::info] updated loginInfo>>>>>>>>>>>>>>> ', loginInfo);

    set((state) => ({ loginInfo: { ...state.loginInfo, ...loginInfo } }));
  },
  initLoginInfo() {
    console.log('[Zustand::info] loginInfo initLoginInfo>>>>>>>>>>>>>>> ');

    set(() => ({ loginInfo: initialState }));
  }
}));
