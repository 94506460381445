import getConfig from 'next/config';
/**
 * 실행 환경 관련 변수
 */
/* eslint-disable turbo/no-undeclared-env-vars */
export const MODE = process.env.NEXT_PUBLIC_MODE as 'prod' | 'stage' | 'local' | 'dev';
export const IS_DEV = MODE === 'stage' || MODE === 'dev' || MODE === 'local';
export const APP_NAME = getConfig()?.publicRuntimeConfig?.packageName as 'hlp-direct' | 'hlp-int' | 'hlp-micro';
export const IS_PROXY = APP_NAME !== 'hlp-int';
export const MI_BASE_PATH = getConfig()?.publicRuntimeConfig?.basePath as '/mi' | undefined;
export const IS_PUBLIC_NETWORK = MODE === 'prod' || MODE === 'stage';
