import React from 'react';
import { useTheme } from '@hanwhalife/shared-libs/emotion/react';
import { Button, Feedback, IconColorProblem } from '@hanwhalife/shared-libs/design-system';
import { StyledError } from '@hanwhalife/styled/_shared';
import { errorMessage } from '@hanwhalife/constants/message/errorMessage';
import { useErrorBoundaryContext } from './hooks/useErrorBoundaryContext';
import { CsBanner } from '@hanwhalife/styled/_shared/components/ui/cs-banner';

/**
 * 에러 폴백 컴포넌트 Props
 */
export interface IErrorFallback {
  /** 공용 에러 기본 메시지 노출 유형 */
  sharedErrorMessageType?: 'retry' | 'default';

  /** 에러 타이틀 */
  errorTitle?: React.ReactNode;

  /** 에러 코드 노출여부 */
  showErrorCode?: boolean;

  /** 에러 내용 */
  errorContents?: React.ReactNode;

  /** 조치 버튼명 */
  ctaButtonName?: React.ReactNode;

  /** Feedback CTA 커스텀 */
  feedBackCTAButtonArea?: React.ReactNode;

  /** 에러시 실행할 함수 */
  onClickCTA?: (e: any) => void;

  /** Wrapper Class */
  wrapperClass?: string;

  /** 고객센터 피드백 영역 영역 */
  useDefaultCustomerCenterFeedback?: React.ReactNode;

  /** 하단 커스텀 영역 영역 */
  bottomArea?: React.ReactNode;

  /** 커스텀이 필요한 경우 에러 fallback 영역(에러정보는 useErrorBoundaryContext에서 참조하세요.) */
  customFeedBack?: React.ReactNode;
}

/**
 * 에러 fallback 컴포넌트
 * @param param0
 * @returns
 */
export const ErrorFallback = ({
  sharedErrorMessageType = 'retry',
  wrapperClass,
  errorTitle,
  showErrorCode,
  errorContents,
  ctaButtonName,
  onClickCTA,
  feedBackCTAButtonArea,
  useDefaultCustomerCenterFeedback,
  bottomArea,
  customFeedBack
}: IErrorFallback) => {
  const { error, resetErrorBoundary } = useErrorBoundaryContext();
  const theme = useTheme();

  return (
    <StyledError className={wrapperClass}>
      {/* 커스텀하게 사용할 경우 (useErrorBoundaryContext 훅으로 error 정보를 참조하세요.) */}
      {customFeedBack ?? (
        <>
          <Feedback
            className={`text-center ${sharedErrorMessageType === 'retry' ? `mb-0 mt-0` : `fill-feedback`}`}
            icon={() => <IconColorProblem width="64" height="64" aria-hidden="true" />}
            title={
              <>
                <div className="typography-subtitle2 text-body1">
                  {errorTitle ?? errorMessage[sharedErrorMessageType].title}
                </div>
              </>
            }
            bodyContents={
              <div className="typography-body2 text-body3">
                {showErrorCode && <div>[{error.errorCode}]</div>}
                <div>{errorContents ?? errorMessage[sharedErrorMessageType].content}</div>
              </div>
            }
            ctaButton={
              <>
                {feedBackCTAButtonArea ?? (
                  <Button size="small" onClick={onClickCTA ?? resetErrorBoundary}>
                    {ctaButtonName ?? errorMessage[sharedErrorMessageType].actionMessage}
                  </Button>
                )}
              </>
            }
          />
          {useDefaultCustomerCenterFeedback && (
            <>
              {/* 고객센터 배너 */}
              <CsBanner />

              <Button tagName="a" size="large" block onClick={onClickCTA} className="mt-8">
                {`${ctaButtonName ?? '돌아가기'}`}
              </Button>
            </>
          )}
          {bottomArea && bottomArea}
        </>
      )}
    </StyledError>
  );
};
