import { useEffect } from 'react';
import { initializeStringUtils } from '@hanwhalife/shared-utils';

/**
 * 크로스 브라우징 관련 초기화
 */
export const useInitializeCrossBrowser = () => {
  useEffect(() => {
    initializeStringUtils();

    applyPromiseWithResolversPolyfill();
  }, []);
};

/**
 * Promise.withResolvers 폴리필 함수
 */
const applyPromiseWithResolversPolyfill = () => {
  if (typeof Promise.withResolvers !== 'function') {
    console.log('applyPromiseWithResolversPolyfill 실행::');

    (Promise as any).withResolvers = function () {
      let resolve: any;
      let reject: any;

      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });

      return {
        promise,
        resolve,
        reject
      };
    };
  }
};
