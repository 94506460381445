import { useEffect, useState } from 'react';

const INIT_QUERY_KEY = 'init_query';

/**
 * 최초 진입시 쿼리스트링을 저장합니다.
 */
export const useKeepInitialQueryString = () => {
  useEffect(() => {
    const initQuery = new URLSearchParams(location.search);

    if (initQuery.size > 0) {
      // 저장을 굳이 안해도 되는 건 삭제
      initQuery.delete('redirectUrl');
      sessionStorage.setItem(INIT_QUERY_KEY, initQuery.toString());
    }
  }, []);
};

/**
 * 최초 진입시 쿼리스트링을 리턴합니다.
 * @returns 최초 진입시 쿼리스트링('?' 물음표 제외)
 */
export const useLoadInitialQueryString = () => {
  const [initQuery, setInitQuery] = useState('');
  //
  useEffect(() => {
    //
    setInitQuery(sessionStorage.getItem(INIT_QUERY_KEY) ?? '');
  }, []);

  return initQuery;
};
