import { Spinner } from '@hanwhalife/shared-libs/design-system';
import { usePortalStore } from '@/store/portal/portalStore';
import {
  useInitializeLoginInfo,
  useInitAppWebInterfaceFunctions,
  useInitializeCrossBrowser,
  useSetHistoryBlockData,
  useInitializeSentryConfig
} from '@hanwhalife/reactjs/hooks/init';

import { useLoginInfo } from '@hanwhalife/shared-store';
import { useQueryClient } from '@hanwhalife/shared-libs/react-query';

/**
 * 초기화할 것들 집합
 * @returns
 */
export const Initialize = () => {
  const { loginInfo } = useLoginInfo();
  const { isInitialized } = loginInfo;

  //! 해당 부분 이슈 없는지 모니터링 필요 (api가 안불러와질수도 있을 거 같은데)
  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      enabled: isInitialized
    }
  });

  const { spinnerStore } = usePortalStore();

  // 전역 로그인 데이터 세팅
  useInitializeLoginInfo({ initLoad: true });

  // App -> Web 호출 함수 등록
  useInitAppWebInterfaceFunctions();

  // 크로스브라우징 처리
  useInitializeCrossBrowser();

  // 센트리 설정
  useInitializeSentryConfig();

  // 히스토리백 막기 초기화
  useSetHistoryBlockData();

  return <>{spinnerStore && <Spinner isSpinning position="fixed-center" />}</>;
};
